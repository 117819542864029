import React from 'react';
import './swiss.scss';

export const swiss = (props) => {

    return (
      <div className="_swiss">


          <div className="_flag">



          </div>

      </div>
    );

};


